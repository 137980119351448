<template>
  <div class="item">
    <div class="mini-toolbar" :class="{ expanded: !collapsed }">
      <div
        class="clicable no-select item-title"
        v-on:click.stop.prevent="onClick"
        :title="info"
      >
        <i class="fa fa-arrows-v drag-icon" style=""></i>
        <span v-if="checkable">
          <i
            :class="value.checked ? 'fa fa-check-square-o' : 'fa fa-square-o'"
            style="min-width: 14px"
          ></i>
        </span>
        <span v-else>
          <i :class="icon"></i>
        </span>
        <span style="margin-left: 4px">{{
          collapsed && value.chartOptions && value.chartOptions.name
            ? getDataLabel(value)
            : $tc("serie", 1)
        }}</span>
      </div>
      <div class="clicable no-select item-toolbar">
        <span
          v-if="!checkable"
          class="btn btn-xs"
          v-on:click.stop.prevent="enabled = !enabled"
        >
          <i class="fa fa-eye" v-if="enabled"></i>
          <i class="fa fa-eye-slash" v-else></i>
        </span>
        <span
          class="btn btn-xs"
          v-on:click.stop.prevent="$emit('removeData', index)"
        >
          <i class="fa fa-trash"></i>
        </span>
        <span class="btn btn-xs" v-on:click.stop.prevent="toggle()">
          <i
            class="fa"
            :class="
              collapsed ? 'fa-caret-square-o-down' : 'fa-caret-square-o-up'
            "
          ></i>
        </span>
      </div>
    </div>
    <div class="form-container" v-if="!collapsed">
      <div class="form-group form-group-sm">
        <label>
          {{ $t("name") }}
        </label>
        <input
          type="text"
          class="form-control"
          v-model="value.chartOptions.name"
        />
      </div>
      <DataSimulationToggle :equipmentData="equipmentData" />
      <div class="row">
        <div class="form-group form-group-sm col-xs-9">
          <label>
            {{ $t("type") }}
          </label>
          <div class="input-group" style="">
            <div class="input-group-btn">
              <button
                type="button"
                class="btn dropdown-toggle btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :disabled="!changeType"
              >
                {{ $t(value.chartOptions.type) }}
                <span class="caret" v-if="changeType"></span>
              </button>
              <ul class="dropdown-menu">
                <li v-for="(item, ix0) in types" :key="ix0">
                  <a href="#" @click.prevent="$emit('changeType', item)">{{
                    $t(item)
                  }}</a>
                </li>
              </ul>
            </div>

            <div
              class="input-group-btn"
              v-if="value.chartOptions.type == 'line'"
            >
              <button
                type="button"
                class="btn dropdown-toggle btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style=""
              >
                {{ $t(value.chartOptions.lineStyle.waveForm) }}
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li v-for="(item, ix) in waveForms" :key="ix">
                  <a
                    href="#"
                    @click.prevent="
                      value.chartOptions.lineStyle.waveForm = item
                    "
                    >{{ $t(item) }}</a
                  >
                </li>
              </ul>
            </div>
            <div
              class="input-group-btn"
              v-if="value.chartOptions.type == 'line'"
            >
              <button
                type="button"
                class="btn dropdown-toggle btn-sm"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style=""
              >
                {{ value.chartOptions.lineStyle.width }}
                <span class="caret"></span>
              </button>
              <ul class="dropdown-menu">
                <li v-for="(item, ix1) in sizes" :key="ix1">
                  <a
                    href="#"
                    @click.prevent="value.chartOptions.lineStyle.width = item"
                    >{{ item }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="form-group form-group-sm col-xs-3 text-right">
          <label style="padding-right: 4px">
            {{ $tc("color", 1) }}
          </label>
          <ColorPicker
            v-model="value.chartOptions.itemStyle.color"
            :pickerStyle="{ left: '-200px' }"
            icon="foreground"
          />
        </div>
      </div>
      <div class="row" v-if="value.chartOptions.type == 'pie'">
        <div class="form-group form-group-sm col-xs-12">
          <div style="">
            <div class="pull-left">{{ $t("expression") }}</div>
            <div class="pull-right">
              <span
                class="clicable no-select"
                @click.stop.prevent="historySearch = !historySearch"
                >{{ $t("history") }}
              </span>
              <input
                type="checkbox"
                v-model="historySearch"
                style="vertical-align: text-top"
              />
            </div>
          </div>
          <div style="clear: both">
            <JSPathPicker
              v-model="value.chartOptions.itemStyle.expression"
              :entry="entry"
              :append="false"
              entryType="data"
            />
          </div>
        </div>
        <div class="form-group form-group-sm col-xs-12">
          <div class="simple-label">{{ $t("label") }}</div>
          <div class="input-group">
            <div
              class="input-group-addon btn addon-btn"
              style="padding: 0px 6px"
              @click.stop.prevent="
                value.chartOptions.label.show = !value.chartOptions.label.show
              "
            >
              <i class="fa fa-eye" v-if="value.chartOptions.label.show"></i>
              <i class="fa fa-eye-slash" v-else></i>
            </div>
            <div
              class="input-group-addon btn addon-btn"
              style="padding: 0px 1px 0 3px"
            >
              <ColorPicker
                class="color-picker"
                :pickerStyle="{ left: '-80px' }"
                v-model="value.chartOptions.label.color"
              />
            </div>
            <!-- TODO: add tooltip http://echarts.apache.org/en/option.html#series-pie.label.formatter -->
            <input
              class="form-control"
              :placeholder="$t('format')"
              v-model="value.chartOptions.label.formatter"
            />
          </div>
        </div>
      </div>
      <template v-else>
        <div class="row inline-options">
          <div class="col-xs-3" v-if="value.chartOptions.type == 'line'">
            <label
              class="checkbox-inline checkbox-inline-bottom-margin no-select"
            >
              <input type="checkbox" v-model="showArea" />
              {{ $t("area") }}
            </label>
          </div>
          <div class="col-xs-4" v-if="symbol">
            <label
              class="checkbox-inline checkbox-inline-bottom-margin no-select"
            >
              <input type="checkbox" v-model="value.chartOptions.showSymbol" />
              {{ $t("show_symbol") }}
            </label>
          </div>
          <div class="col-xs-5">
            <label
              class="checkbox-inline checkbox-inline-bottom-margin no-select"
            >
              <input type="checkbox" v-model="value.chartOptions.animation" />
              {{ $t("animation") }}
            </label>
          </div>
        </div>
        <div class="row">
          <div
            class="form-group form-group-sm col-xs-12"
            style="margin-top: 6px"
          >
            <div>
              <span class="pull-left text-bold">
                {{ $tc("expression", 1) }}
              </span>
              <span
                class="pull-right"
                v-if="
                  value.chartOptions.itemStyle.expression && showApplyExpresson
                "
              >
                {{ $tc("apply") }}
                <i
                  class="toolbar-btn clicable"
                  :class="`${icon} ${
                    expressionSlot.chart ? 'text-primary' : ''
                  }`"
                  :title="`${$t('apply_to')} ${$t('chart')}`"
                  @click.stop.prevent="toggleExpression('chart')"
                ></i>
                <i
                  class="fa fa-table toolbar-btn clicable"
                  :class="expressionSlot.table ? 'text-primary' : ''"
                  :title="`${$t('apply_to')} ${$t('table')}`"
                  @click.stop.prevent="toggleExpression('table')"
                ></i>
              </span>
            </div>
            <div class="input-group" style="clear: both">
              <div class="input-group-addon"><i class="fa fa-code"></i></div>
              <input
                class="form-control"
                type="text"
                v-model="value.chartOptions.itemStyle.expression"
                placeholder="$value * 1000"
                name="expression"
              />
            </div>
          </div>
        </div>
      </template>
      <div class="row">
        <div class="form-group form-group-sm col-xs-12" style="margin-top: 6px">
          <div>
            <span class="pull-left text-bold">{{ $tc("filter", 1) }}:</span>
            <span
              v-if="
                value.chartOptions.validation &&
                value.chartOptions.itemStyle.expression
              "
              class="pull-right clicable"
              @click.stop.prevent="validationReadValue = !validationReadValue"
            >
              {{ $t("read_value") }}
              <i
                class="fa toolbar-btn"
                :class="
                  validationReadValue ? 'fa-check-square-o' : 'fa-square-o'
                "
              ></i>
            </span>
          </div>
          <div class="input-group" style="clear: both">
            <div class="input-group-addon"><i class="fa fa-filter"></i></div>
            <input
              class="form-control"
              type="text"
              v-model="value.chartOptions.validation"
              placeholder="$value > 0"
              name="validation"
            />
          </div>
        </div>
      </div>
      <div class="row" v-if="showInterpolation">
        <div class="col-xs-12">
          <div class="form-group form-group-sm">
            <label for="" style="white-space: nowrap">
              {{ $t("missing_values") }}
            </label>
            <template v-for="(item, ix) in cellInterpolation">
              <div
                v-if="item.enabled"
                :key="ix * 10"
                class="clicable option"
                :class="missingValues == item.value ? 'text-primary' : ''"
                @click.stop.prevent="missingValues = item.value"
              >
                <i
                  :class="
                    missingValues == item.value
                      ? 'fa fa-dot-circle-o'
                      : 'fa fa-circle-o'
                  "
                ></i>
                {{ $t(item.label) }}
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectableObjects from "@/assets/dashboard/selectable_objects.json";
import ColorPicker from "@/components/editor/color-picker.vue";
import JSPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import DataSimulationToggle from "@/components/control-sidebar/property-editors/data-simulation-toggle.vue";
export default {
  name: "ChartSerieForm",
  props: {
    value: {
      type: Object,
      required: false,
      default: () => null
    },
    index: {
      type: Number,
      required: true
    },
    equipmentData: {
      type: Object,
      required: false,
      default: () => null
    },
    changeType: {
      type: Boolean,
      default: true,
      required: false
    },
    checkable: {
      type: Boolean,
      required: false,
      default: false
    },
    types: {
      type: Array,
      required: false,
      default: () => ["line", "bar", "pie"]
    },
    symbol: {
      type: Boolean,
      required: false,
      default: true
    },
    showApplyExpresson: {
      type: Boolean,
      required: false,
      default: true
    },
    showInterpolation: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    ColorPicker,
    JSPathPicker,
    DataSimulationToggle
  },
  data() {
    return {
      collapsed: true,
      historySearch: false,
      waveForms: ["square", "triangle", "sin"],
      sizes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      cellInterpolation: [
        {
          value: "",
          label: "standard",
          enabled: true
        },
        {
          value: "last_value",
          label: "last_value",
          enabled: true
        },
        {
          value: "linear_interpolation",
          label: "linear_interpolation",
          enabled: true
        }
      ]
    };
  },
  computed: {
    entry() {
      var entry = JSON.parse(JSON.stringify(SelectableObjects.data));
      if (this.equipmentData) {
        this.$utils.copyIfExists(
          JSON.parse(JSON.stringify(this.equipmentData)),
          entry
        );
        if (this.historySearch) {
          let entries = JSON.parse(
            JSON.stringify(this.$store.getters["history/entries"] || {})
          );
          let history =
            (entries || {})[entry.id] ||
            this.$store.getters["history/defHistoryData"];
          delete history.samples; // it avoids too deep search
          delete history.interval;
          entry = {
            history: {
              ...history
            },
            ...entry
          };
        }
      }
      return entry;
      // let data = null;
      // if (this.equipmentData) {
      //   data = JSON.parse(JSON.stringify(this.equipmentData));
      //   if (this.historySearch) {
      //     let entries = JSON.parse(
      //       JSON.stringify(this.$store.getters["history/entries"] || {})
      //     );
      //     let history =
      //       (entries || {})[data.id] ||
      //       this.$store.getters["history/defHistoryData"];
      //     delete history.samples; // it avoids too deep search
      //     delete history.interval;
      //     data = {
      //       history: {
      //         ...history
      //       },
      //       ...data
      //     };
      //   } else {
      //     data = JSON.parse(JSON.stringify(this.equipmentData));
      //   }
      // }
      // return data;
    },
    icon() {
      return {
        line: "fa fa-line-chart",
        bar: "fa fa-bar-chart",
        pie: "fa fa-pie-chart"
      }[this?.value?.chartOptions?.type || "line"];
    },
    enabled: {
      set(value) {
        this.$emit("enable", value);
      },
      get() {
        return this?.value?.enabled ? true : false;
      }
    },
    checked: {
      set(value) {
        this.$emit("check", value);
      },
      get() {
        return this?.value?.checked ? true : false;
      }
    },
    expressionSlot: {
      set(value) {
        this.$set(this.value.chartOptions.itemStyle, "expressionSlot", value);
      },
      get() {
        return (
          this?.value?.chartOptions?.itemStyle?.expressionSlot || {
            chart: true,
            table: false
          }
        );
      }
    },
    validationReadValue: {
      set(value) {
        this.$set(this.value.chartOptions, "validationReadValue", value);
      },
      get() {
        return "validationReadValue" in this?.value?.chartOptions
          ? this?.value?.chartOptions.validationReadValue
          : true;
      }
    },
    missingValues: {
      set(value) {
        this.$set(this.value.chartOptions.itemStyle, "missingValues", value);
      },
      get() {
        let dft =
          this?.chartOptions?.lineStyle?.waveForm == "square"
            ? "last_value"
            : "";
        return this?.value?.chartOptions?.itemStyle?.missingValues || dft;
      }
    },
    referenceConnectorId() {
      return (
        (this.$store.getters["dashboard/screenRefMap"](this.screenId) || {})
          ?.conn1 || 0
      );
    },
    dashboardConnectorId() {
      return this.$store.getters["dashboard/dashboardEquipmentId"];
    },
    info() {
      const _info = (data) => {
        let data_name = data?.name ?? "";
        if (data?.identity_embedded_app) {
          data_name = `${data?.name}\n\t\t${data?.identity_embedded_app}`;
        }

        return `${data?.device?.connector?.id ?? ""} - ${data?.device?.connector
          ?.name ?? ""}\n\t→ ${data?.device?.id ?? ""} - ${data?.device?.name ??
          ""}\n\t\t→ ${data?.id ?? ""} - ${data_name}`;
      };
      let data = this.equipmentData;
      if (data) {
        return _info(data);
      } else if (!data && this.dashboardConnectorId && this.value.data_id) {
        // try to find it from the reference_id
        data = this.$store.getters["dashboard/dataList"].find(
          ({ clp_id, id, reference_id }) =>
            parseInt(clp_id) == parseInt(this.dashboardConnectorId) &&
            ((!isNaN(parseInt(this.value.data_id)) &&
              parseInt(this.value.data_id) == parseInt(id)) ||
              reference_id == this.value.data_id)
        );
        if (!data) {
          // still not found, try to show something...
          let lst = [];
          this.$store.getters["dashboard/dataList"]
            .filter(({ reference_id }) => reference_id == this.value.data_id)
            .map((i) => {
              return lst.push(_info(i));
            });
          if (lst.length) {
            return lst.join("\n---\n");
          }
        }
      }
      return this.$t("not_found");
    },
    dataDisplayLabel() {
      return this.$store.getters["dashboard/dataDisplayLabel"];
    },
    showArea: {
      set(value) {
        this.$set(this.value.chartOptions.itemStyle, 'showArea', value);
      },
      get() {
        return this?.value?.chartOptions?.itemStyle?.showArea ?? false;
      }
    }
  },
  methods: {
    toggleExpression(slot) {
      let entry = JSON.parse(JSON.stringify(this.expressionSlot));
      entry[slot] = !entry[slot];
      this.expressionSlot = entry;
    },
    onClick() {
      if (this.checkable) {
        this.checked = !this.checked;
      } else {
        this.toggle();
      }
    },
    toggle() {
      this.collapsed = !this.collapsed;
      this.$emit(this.collapsed ? "close" : "open");
    },
    getDataLabel(value) {
      if (value?.chartOptions?.identity_embedded_app && this.dataDisplayLabel == 'identity_embedded_app') {
        return value?.chartOptions?.identity_embedded_app ?? "";
      }
      return value?.chartOptions?.name ?? "";
    }
  }
};
</script>

<style scoped>
div.item {
  position: relative;
  width: 100%;
  font-size: 1em;
  border-top: 1px solid #e4e4e4;
  z-index: 1;
  padding: 4px 0;
}

div.mini-toolbar.expanded {
  color: #337ab7;
  font-weight: 600;
}

div.item-title {
  font-weight: 500;
  white-space: normal;
  width: 100%;
}

.drag-icon {
  margin: 0 4px 0 -6px;
  color: #999;
}

div.item-toolbar {
  position: absolute;
  top: 2px;
  right: 0;
  background: #ffffffcc;
}

div.item-toolbar > i {
  padding: 0 3px;
  min-width: 22px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.form-container {
  clear: both;
  padding-bottom: 16px;
  background-color: white;
  z-index: 3;
}

.form-group {
  margin-bottom: 4px;
}

.clicable {
  cursor: pointer;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.input-group-btn > .dropdown-toggle {
  background-color: transparent;
  display: block;
  padding: 5px 0;
}

/* chart type dropdown */
.btn.dropdown-toggle.btn-sm[disabled] {
  opacity: 1;
  cursor: revert;
}

.toolbar-btn {
  margin: 0 2px 0 5px;
  min-width: 16px;
}

div.option {
  margin: 3px;
  white-space: nowrap;
}

div.option > i {
  margin-right: 3px;
}

div.inline-options {
  margin-bottom: 15px;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  padding: 5px;
}
</style>
