<template>
  <TogglePanel :title="$t('titles.tooltip')">
    <div>
      <label
        :class="!customEnabled ? 'text-primary clicable' : 'clicable'"
        @click.stop.prevent="customEnabled = !customEnabled"
      >
        <i
          :class="customEnabled ? 'fa fa-circle-o' : 'fa fa-check-circle-o'"
        ></i>
        {{ $t("default") }}
      </label>
      <label
        :class="customEnabled ? 'text-primary clicable' : 'clicable'"
        @click.stop.prevent="customEnabled = !customEnabled"
        style="margin-left: 20px"
      >
        <i
          :class="!customEnabled ? 'fa fa-circle-o' : 'fa fa-check-circle-o'"
        ></i>
        {{ $t("customized") }}
      </label>
      <Tooltip :title="$t('hints.chart_tooltip')" class="label-hint" />
    </div>
    <div
      v-if="customEnabled"
      style="
        margin: 5px -5px 5px -5px;
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 10px;
      "
    >
      <div class="form-group form-group-sm">
        <label
          for=""
          class="clicable"
          @click.stop.prevent="wordWrap = !wordWrap"
        >
          <i :class="!wordWrap ? 'fa fa-check-square-o' : 'fa fa-square-o'"></i>
          {{ $t("word_wrap") }}
        </label>
        <Tooltip :title="$t('hints.chart_word_wrap')" class="label-hint" />
        <div class="pull-right"><span class="btn btn-xs">em</span></div>
        <div class="input-group">
          <div class="input-group-addon">
            {{ $t("height") }}
          </div>
          <input
            type="number"
            class="form-control text-center"
            placeholder="auto"
            step="0.1"
            min="0.5"
            max="3.0"
            v-model="lineHeight"
          />
          <div
            class="input-group-addon btn"
            @click.stop.prevent="lineHeight = dftTooltip.config.line_height"
            :title="$t('restore')"
          >
            <i class="fa fa-undo"></i>
          </div>
          <div
            class="input-group-addon btn"
            @click.stop.prevent="lineHeight = ''"
            :title="$t('erase')"
          >
            <i class="fa fa-close"></i>
          </div>
        </div>
      </div>
      <FontSelector v-model="font" :editable="customFontEnabled" />
      <div class="form-group form-group-sm">
        <label
          for=""
          class="clicable"
          @click.stop.prevent="positionEnabled = !positionEnabled"
        >
          <i
            :class="positionEnabled ? 'fa fa-check-square-o' : 'fa fa-square-o'"
          ></i>
          {{ $t("position") }}
        </label>
        <Tooltip
          :title="$t('hints.chart_tooltip_position')"
          class="label-hint"
        />
        <div class="pull-right" v-if="positionEnabled">
          <span
            class="btn btn-xs"
            @click.stop.prevent="
              positionManualValueUnit =
                positionType == 'preset'
                  ? 'px'
                  : positionManualValueUnit == '%'
                  ? 'px'
                  : '%'
            "
            >{{ positionManualValueUnit }}
          </span>
        </div>
        <input
          type="text"
          class="form-control text-center"
          disabled
          :value="$t('standard')"
          v-if="!positionEnabled"
        />
        <div class="input-group" v-else>
          <div class="input-group-addon addon-fixed">
            <select class="" v-model="positionType">
              <option value="preset">{{ $t("preset") }}</option>
              <option value="manual">{{ $t("manual") }}</option>
            </select>
          </div>
          <select
            class="form-control"
            v-if="positionType == 'preset'"
            v-model="positionPresetValue"
          >
            <option value="top_left">{{ $t("top_left") }}</option>
            <option value="top_center">{{ $t("top_center") }}</option>
            <option value="top_right">{{ $t("top_right") }}</option>
            <option value="bottom_left">{{ $t("bottom_left") }}</option>
            <option value="bottom_center">{{ $t("bottom_center") }}</option>
            <option value="bottom_right">{{ $t("bottom_right") }}</option>
          </select>
          <template v-else>
            <div class="form-control manual-pos">
              <div class="manual-pos-label" style="left: 0">X</div>
              <div class="manual-pos-label" style="left: calc(49% + 2px)">
                Y
              </div>
              <input
                type="number"
                inc="1"
                class="manual-pos-input"
                placeholder="auto"
                v-model="positionManualValueX"
              />
              <input
                type="number"
                inc="1"
                class="manual-pos-input"
                placeholder="auto"
                v-model="positionManualValueY"
              />
            </div>
          </template>
        </div>
        <div
          class="input-group"
          v-if="positionEnabled && positionType == 'preset'"
        >
          <div class="input-group-addon addon-fixed">Offset</div>
          <div class="form-control manual-pos">
            <div class="manual-pos-label" style="left: 0">X</div>
            <div class="manual-pos-label" style="left: calc(49% + 2px)">Y</div>
            <input
              type="number"
              inc="1"
              class="manual-pos-input"
              placeholder="auto"
              v-model="positionManualValueX"
            />
            <input
              type="number"
              inc="1"
              class="manual-pos-input"
              placeholder="auto"
              v-model="positionManualValueY"
            />
          </div>
        </div>
      </div>
      <div class="form-group form-group-sp">
        <label
          class="clicable"
          @click.stop.prevent="openOnStartup = !openOnStartup"
        >
          <i
            :class="openOnStartup ? 'fa fa-check-square-o' : 'fa fa-square-o'"
          ></i>
          {{ $t("show_at_startup") }}
        </label>
        <Tooltip :title="$t('hints.show_at_startup')" class="label-hint" />
      </div>
      <div class="form-group form-group-sm">
        <label
          class="clicable"
          @click.stop.prevent="keepVisible = !keepVisible"
        >
          <i
            :class="keepVisible ? 'fa fa-check-square-o' : 'fa fa-square-o'"
          ></i>
          {{ $t("titles.keep_visible") }}
        </label>
        <Tooltip :title="$t('hints.keep_visible')" class="label-hint" />
      </div>
      <div class="text-center">
        <span
          class="btn btn btn-xs btn-default"
          @click.stop.prevent="toggleTooltip"
          >{{ $t("titles.try_it") }}</span
        >
      </div>
    </div>
    <div style="margin-top: 15px">
      <div class="form-group form-group-sm">
        <label class="checkbox-inline">
          <input type="checkbox" v-model="lastValue" />
          {{ $t("shows_last_recorded_value") }}
        </label>
        <Tooltip
          :title="$t('hints.cell_interpolation.last_value')"
          class="label-hint"
        />
      </div>
    </div>
  </TogglePanel>
</template>

<script>
import Tooltip from "@/components/tooltip.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import FontSelector from "@/components/control-sidebar/property-editors/font-selector.vue";
const dftTooltip = () => ({
  show: true,
  trigger: "axis",
  axisPointer: {
    animation: false
  },
  formatter: null,
  config: {
    position_enabled: false,
    type: "preset",
    preset_value: "top_right",
    manual_value_x: "0",
    manual_value_y: "0",
    manual_unit: "px",
    font: {
      "font-family": "Source Sans Pro",
      "font-size": "80%",
      "font-style": "normal",
      "font-weight": "normal",
      "text-decoration": "none"
    },
    word_wrap: false,
    line_height: "1.1em",
    keep_visible: false,
    open_on_startup: false
  },
  last_value: true
});

export { dftTooltip };

export default {
  name: "ChartTooltipForm",
  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  components: {
    TogglePanel,
    Tooltip,
    FontSelector
  },
  data: () => ({
    tooltip: null
  }),
  computed: {
    customEnabled: {
      set(value) {
        let config = null;
        if (value) {
          config = dftTooltip().config;
          config.font = null;
        }
        this.$set(this.tooltip, "config", config);
        this.$emit("input", this.tooltip);
      },
      get() {
        return this.tooltip.config ? true : false;
      }
    },
    positionEnabled: {
      set(value) {
        this.tooltipConfig("position_enabled", value);
      },
      get() {
        return this.tooltipConfig("position_enabled");
      }
    },
    positionType: {
      set(value) {
        this.tooltipConfig("type", value);
        // reset, since it will might be used as offset too
        this.positionManualValueUnit = "px";
        this.positionManualValueX = 0;
        this.positionManualValueY = 0;
      },
      get() {
        return this.tooltipConfig("type");
      }
    },
    positionPresetValue: {
      set(value) {
        this.tooltipConfig("preset_value", value);
      },
      get() {
        return this.tooltipConfig("preset_value");
      }
    },
    positionManualValueX: {
      set(value) {
        this.tooltipConfig("manual_value_x", value);
      },
      get() {
        return this.tooltipConfig("manual_value_x");
      }
    },
    positionManualValueY: {
      set(value) {
        this.tooltipConfig("manual_value_y", value);
      },
      get() {
        return this.tooltipConfig("manual_value_y");
      }
    },
    positionManualValueUnit: {
      set(value) {
        this.tooltipConfig("manual_unit", value);
      },
      get() {
        return this.tooltipConfig("manual_unit");
      }
    },
    wordWrap: {
      set(value) {
        this.tooltipConfig("word_wrap", value);
      },
      get() {
        return this.tooltipConfig("word_wrap");
      }
    },
    keepVisible: {
      set(value) {
        this.tooltipConfig("keep_visible", value);
      },
      get() {
        return this.tooltipConfig("keep_visible");
      }
    },
    font: {
      set(value) {
        this.tooltipConfig("font", value);
      },
      get() {
        return this.tooltipConfig("font");
      }
    },
    lineHeight: {
      set(value) {
        value = `${value}`.replace(/[^0-9.]/g, "");
        this.tooltipConfig("line_height", `${value}em`);
      },
      get() {
        return this.tooltipConfig("line_height").replace(/[^0-9.]/g, "");
      }
    },
    openOnStartup: {
      set(value) {
        this.tooltipConfig("open_on_startup", value);
      },
      get() {
        return this.tooltipConfig("open_on_startup");
      }
    },
    customFontEnabled() {
      return this.tooltip.config && this.tooltip.config.font ? true : false;
    },
    dftTooltip: dftTooltip,
    lastValue: {
      set(value) {
        let tooltip = this.tooltip || {};
        this.$set(tooltip, "last_value", value);
        this.$emit("input", this.tooltip);
      },
      get() {
        return this?.tooltip?.last_value ?? true;
      }
    }
  },
  methods: {
    tooltipConfig(attr, value) {
      if (!this.customEnabled) return null;
      let tooltip = this.tooltip || {};
      let config = tooltip.config;
      if (value === undefined) {
        let vlr = (config || {})[attr];
        return vlr ?? dftTooltip().config[attr];
      } else {
        if ((config || {})[attr] === undefined) {
          config = config || {};
          this.$set(config, attr, undefined);
        }
        config[attr] = value;
        this.$set(this.tooltip, "config", config);
        this.$emit("input", this.tooltip);
      }
    },
    toggleTooltip() {
      this.$root.$emit("chart:toggleToolTip");
    }
  },
  created() {
    this.tooltip = { ...{ config: null }, ...(this.value || {}) };
    // this.tooltip = JSON.parse(JSON.stringify({(dftTooltip()), ...this.value}));
  }
};
</script>

<style scoped>
.addon-fixed {
  padding: 0;
  min-width: 100px;
}
.addon-fixed > select {
  border: 0;
  outline: none;
  background: transparent;
}
.addon-fixed > select:focus,
.addon-fixed > select:focus-visible {
  outline: none;
  border: 0;
}

.manual-pos {
  padding: 0;
  position: relative;
}
.manual-pos-label {
  position: absolute;
  top: 0px;
  margin-left: 2px;
  font-size: 80%;
}

input.manual-pos-input {
  width: 49%;
  border-width: 0px;
  outline-width: 0px;
  padding: 5px 5px 5px 10px;
  background-color: transparent;
  text-align: center;
}

input.manual-pos-input:last-child {
  border-left: 1px solid rgb(221, 221, 221);
}
.label-hint {
  margin-left: 5px;
}
</style>